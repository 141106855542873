<template>
    <div id="investor">
        <div class="banner">
            <div>
                <div class="lf">
                    <h1>{{ $t('lang.investor') }}</h1>
                    <p>Investor relations</p>
                </div>
                <div class="rf">
                    <div class="stock">
                        <h2>沐邦高科 603398</h2>
                        <div :class="['stock-situation', isIncrease ? 'increase' : '']">
                            <span class="curr-price">{{ parseFloat(stockData.currentValue).toFixed(2) }}</span>
                            <i :class="['arrow', stockData.currentValue > stockData.receivedYesterday ? 'arrow-up' : 'arrow-down']"></i>
                            <span class="increase-percent">{{ stockData.increase }}%</span>
                            <span class="increase-price">{{ (stockData.currentValue - stockData.receivedYesterday).toFixed(2) }}</span>
                            <span class="date">{{ currentTime }}</span>
                        </div>
                        <ul class="overview">
                            <li>
                                <p>今开</p>
                                <p>{{ Number(stockData.todayOpen).toFixed(2) }}</p>
                            </li>
                            <li>
                                <p>最高</p>
                                <p>{{ Number(stockData.highest).toFixed(2) }}</p>
                            </li>
                            <li>
                                <p>最低</p>
                                <p>{{ Number(stockData.minimum).toFixed(2) }}</p>
                            </li>
                            <li>
                                <p>昨收</p>
                                <p>{{ Number(stockData.receivedYesterday).toFixed(2) }}</p>
                            </li>
                            <li>
                                <p>成交量</p>
                                <p>{{ (stockData.volume / 10000).toFixed(2) }} 万手</p>
                            </li>
                            <li>
                                <p>成交额</p>
                                <p>{{ (stockData.turnover / 10000).toFixed(2) }} 万元</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
    import {urls } from '@/lib/common'
    export default {
        name: "Investor",
        components: {},
        data() {
            return {
                otherItems: [
                    {
                        img: require('@/assets/images/about/icon_return.png'),
                        name: '股东回报',
                        msg: '条款/规划/股利分配',
                        url: '/about/college'
                    }, {
                        img: require('@/assets/images/about/icon_protect.png'),
                        name: '保护投资者',
                        msg: '投资者保护宣传',
                        url: ''
                    }, {
                        img: require('@/assets/images/about/icon_communicate.png'),
                        name: '互动交流',
                        msg: '提出宝贵意见和建议',
                        url: ''
                    }, {
                        img: require('@/assets/images/about/icon_education.png'),
                        name: '投资者教育',
                        msg: '风险提示与防范',
                        url: ''
                    }
                ],
                stockUrl: 'http://hq.sinajs.cn/list=sh603398',
                stockData: '',
                isIncrease: false,
                timer: null,
                currentTime: null,
            }
        },
        computed: {
            execIncrease() {
                return (this.stockData.currentValue - this.stockData.receivedYesterday) / this.stockData.receivedYesterday * 100;
            }
        },
        methods: {
            getStockInfo() {
                this.$http.get(urls.getStockData).then(res => {
                    console.log('stock', res)
                    this.stockData = res.data.data
                    this.stockData.currentValue - this.stockData.receivedYesterday > 0 ? this.isIncrease = true : this.isIncrease = false;
                    /*const data = res.data.data.split('="')[1];
                    this.stockData = data.split(',');
                    data[3] - data[2] > 0 ? this.isIncrease = true : this.isIncrease = false;*/
                }).catch(err => {
                    console.log('err', err);
                })
            },
            getCurrentTime() {
                const date = new Date()
                let y = date.getFullYear()
                let m = date.getMonth() + 1
                let d = date.getDate()
                let h = date.getHours()
                let min = date.getMinutes()
                let s = date.getSeconds()
                this.currentTime = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d} ${h < 10 ? '0' + h : h}:${min < 10 ? '0' + min : min}:${s < 10 ? '0' + s : s}`
            }
        },
        mounted() {
            this.getStockInfo();
            this.getCurrentTime()
            this.timer = setInterval(() => {
                this.getStockInfo();
            }, 30000)
        },
        beforeDestroy() {
            clearInterval(this.timer);
            this.timer = null;
        }
    }
</script>

<style lang="less" scoped>
    #investor {
        color: #34254C;
        .navbar {
            position: relative;
        }

        .icon-more {
            display: inline-block;
            width: 1.458vw;
            height: 0.9375vw;
            background: url('../../assets/images/about/icon_more.png') no-repeat center center;
            background-size: 100% 100%;
        }

        .banner {
            width: 100vw;
            height: 32.5vw;
            background: url("../../assets/images/about/ea51c098630dcf017d86a61663c26ebe.png") no-repeat center 25%;
            background-size: 100% 230%;
            position: relative;

            &::before {
                content: '';
                width: 100%;
                height: 100%;
                background-color: rgba(60, 54, 79, 0.85);
                position: absolute;
                top: 0;
                left: 0;
            }

            > div {
                display: flex;
                justify-content: space-between;
                width: 1200px;
                color: #fff;
                margin: auto;
                position: absolute;
                top: 6vw;
                left: 50%;
                margin-left: -600px;
                z-index: 99;

                .lf {
                    h1 {
                        font-size: 64px;
                    }

                    p {
                        color: #707070;
                        font-size: 43px;
                    }
                }

                .rf {
                    width: 609px;
                    box-sizing: border-box;
                    background: rgba(72, 65, 96, .8);
                    border-radius: 6px;

                    .stock {
                        padding: 2vw 1.5vw;

                        h2 {
                            font-size: 28px;
                            font-weight: normal;
                        }

                        .stock-situation {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 1px solid #B9B6CA;
                            padding: 1vw 0;

                            > span:not(.date) {
                                color: #468B2C;
                                font-size: 28px;
                                position: relative;
                            }

                            .arrow {
                                display: inline-block;
                                width: 30px;
                                height: 40px;
                                background: url("../../assets/images/about/icon_arrow_down.png") no-repeat center center;
                                background-size: 100%;
                                position: relative;
                                top: .4vw;
                            }

                            .curr-price {
                                color: #468B2C;
                                font-size: 80px !important;
                            }

                            .increase-percent,
                            .increase-price {
                                top: .7vw;
                            }

                            .date {
                                position: relative;
                                top: .9vw;
                                font-size: 14px;
                            }

                            &.increase {
                                .arrow {
                                    background-image: url("../../assets/images/about/icon_arrow_up.png");
                                }

                                > span:not(.date) {
                                    color: #F04531;
                                }
                            }
                        }

                        .overview {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            text-align: center;
                            border-bottom: 1px solid #B9B6CA;

                            li {
                                padding: 1vw 0;
                                flex: 1;
                                font-size: 18px;
                                line-height: 150%;

                                &:last-child {
                                    flex: 2;
                                }

                                &:nth-child(5) {
                                    flex: 1.5;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    .fontFn2 (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem !important";
    }

    @media screen and (max-width: 750px) {
        #investor{
            .banner{
                .fontFn(170,height);
                margin-bottom: 32%;
                & > div{
                    top:0;
                    left:0;
                    margin:0;
                    width: 100%;
                    height: 100%;
                    .lf{
                        position: absolute;
                        top:8.2%;
                        left:5.3%;
                        h1{
                            .fontFn(22);
                            font-weight: normal;
                            padding-bottom: 2%;
                        }
                        p{
                            .fontFn(15);
                            font-weight: normal;
                            color:rgba(255,255,255,0.7);
                        }
                    }
                    .rf{
                        box-shadow: 0 4px 20px rgba(72, 65, 96, 0.6);
                        position: absolute;
                        top:42%;
                        left:4.2%;
                        width: 92%;
                        .fontFn(20,border-radius);
                        .stock{
                            padding: 5% 4%;
                            background-color: #595774;
                            .fontFn(20,border-radius);
                            h2{
                                font-weight: normal;
                                .fontFn(19);
                            }
                            .stock-situation{
                                padding-right: 24%;
                                padding-top: 4%;
                                padding-bottom: 4%;
                                position: relative;
                                > span:not(.date) {
                                    .fontFn(15);
                                }

                                .curr-price{
                                    .fontFn2(44);
                                }
                                .arrow{
                                    .fontFn(20,width);
                                    .fontFn(30,height);
                                }
                                .date{
                                    position: absolute;
                                    top:-18%;
                                    right:0;
                                    .fontFn(12);
                                }
                            }
                            .overview{
                                li{
                                    .fontFn(12);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>